<script lang="ts">
	import { getTranslate } from '@tolgee/svelte';
	import ArrowRightIcon from '../Icon/ArrowRightIcon.svelte';
	import type { Image$data } from '$houdini';
	import Image from '$lib/components/Image.svelte';

	export let imageData: Image$data;
	export let href: string | null | undefined;
	export let title: string | null | undefined;

	const { t } = getTranslate();
</script>

<div class="w-max max-w-xs sm:max-w-full sm:w-[17.5rem] xl:w-[23rem] 2xlw-[26rem]">
	<a
		{href}
		class="max-w-xs sm:max-w-full sm:w-[17.5rem] lg:w-[19rem]  2xl:w-[26rem]"
		data-elb="course"
		data-elbaction="click:clicked"
		data-elb-course="course_name:{title};course_slug:{href}"
	>
		<div class="flex flex-col items-start cursor-pointer mb-16">
			<Image class="bg-gray-100 overflow-hidden" alt={title} {imageData} />
			<div class="w-full mt-4 flex items-center justify-between">
				<div class="text-sm font-regular">{$t('course-card.subline')}</div>
				<ArrowRightIcon />
			</div>
		</div>
	</a>
</div>
